@import '../../../../assets/styles/variables';

#aside {
    .ant-menu-root {
        background: #f3f7fd;
    }



    .flag-select  {
        display: none;

        @media only screen and (max-width: 499px) {
            display: block;
        }
    }

    .ant-menu-sub {
        background-color: $cabinerBackColor;
    }

    .menuLink {
        font-family: $MontserratRegular;
        font-size: 0.9vw;
        line-height: 0.9vw;
        letter-spacing: 0.1vw;
        color: $greyLight;
        display: flex;
        align-items: center;
        height: 100%;
        user-select: none;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet16px;
            line-height: $tablet16px;
            letter-spacing: 1px;
        }

        @media only screen and (max-width: 499px) {
            font-size: 3vw;
            line-height: 3vw;
            letter-spacing: 1px;
        }

        &:hover {
            transition: all 0.25s;
            color: $blueDark;
        }
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            border-right: 3px solid #1890ff;
            transform: scaleY(0.0001);
            opacity: 0;
            transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            content: '';
        }
    }

    .ant-menu-item-selected {
        background-color: transparent;

        &:after {
            opacity: 0;
        }

        & > a {
            color: $blueDark !important;
        }

        background-color: #e6f7ff;
        font-family: $MontserratRegular;

        &:after {
            transform: scaleY(1);
            opacity: 1;
            transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
            opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        }

    }

    .ant-menu-item {
        padding: 0 !important;
        margin: 0;

        .asideLink {
            font-family: $MontserratRegular;
            font-size: 0.9vw;
            line-height: 0.9vw;
            letter-spacing: 0.1vw;
            color: $greyLight;
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: 5.2vw;
            user-select: none;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet16px;
                line-height: $tablet16px;
                letter-spacing: 1px;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3vw;
                line-height: 3vw;
                letter-spacing: 1px;
            }

            &:hover {
                transition: all 0.25s;
                color: $blueDark;
            }
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                border-right: 3px solid #1890ff;
                transform: scaleY(0.0001);
                opacity: 0;
                transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
                transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
                transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
                content: '';
            }
        }
        .active {
            background-color: #e6f7ff;
            color: $blueDark;
            font-family: $MontserratRegular;

            &:after {
                transform: scaleY(1);
                opacity: 1;
                transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
                -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
    }

    .anticon svg {
        width: 1.3vw;
        height: 1.3vw;
    }

    .ant-menu-item .anticon,
    .ant-menu-submenu-title .anticon {
        margin-right: 1vw;
    }

    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        padding-left: 2.9vw !important;
        height: 4vw;
        line-height: 4vw;
        user-select: none;
    }

    .ant-menu-inline {
        border: none;
    }

    .ant-menu-submenu-title .anticon + span  {
        text-transform: uppercase;
        user-select: none;
        font-family: $MontserratRegular;
        font-size: 0.8vw;
        line-height: 0.8vw;
        letter-spacing: 0.1vw;
    }

    .ant-menu-submenu-selected {
        color: $blueDark;
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item {
        height: 4vw;
        line-height: 4vw;
    }

    .menuItem {
        padding-left: 2.9vw !important;
        height: 4vw;
        line-height: 4vw;
        user-select: none;
    }
}
