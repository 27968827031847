@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.botsWrapper {
    width: 74.6vw;
    margin: 1.8vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__updatecard {
        display: flex;
        align-items: center;
    }

    &__bot {
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 0.8vw;
            line-height: 0.8vw;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            margin-bottom: 1.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 2.14vw;
                line-height: $tablet32px;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.3vw;
                line-height: $mobile24px;
                margin-bottom: 7vw;
            }
        }
    }

    &__wrapperInput {
        width: 15vw;
        position: relative;

        &:not(:nth-child(4n)) {
            margin-right: 2.75vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-right: 0;
            }

            @media only screen and (max-width: 499px) {
                margin-right: 0;
            }
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 23vw;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: 7vw;
            width: 100%;
        }

        &_invalid {
            color: $red;
            bottom: -1.3vw;
            font-family: $MontserratRegular;
            font-weight: 400;
            font-size: 0.8vw;
            width: fit-content;
            position: absolute;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                bottom: -15px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                bottom: -15px;
            }
        }
    }

    &__wrapper {

        &_input {
            height: 3.91vw;
            line-height: 3.91vw;
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            border-radius: 0.1vw;
            border: 1px solid #f2f3f9;
            outline: none;
            padding: 0 1vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: $tablet60px;
                line-height: $tablet60px;
                margin-top: $tablet10px;
                font-size: $tablet16px;
                padding: $tablet12px;
                border: 1px solid #f2f3f9;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                max-width: 100%;
                border: 1px solid #f2f3f9;
                height: $mobile60px;
                line-height: $mobile60px;
                margin-top: $mobile10px;
                font-size: $mobile16px;
                padding: $mobile12px;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (max-width: 1024px) {
                    border: 1px solid $blueDark;
                }
            }

            &::placeholder {
                background: #f2f3f9;
                border-radius: 0.1vw;
                font-family: $MontserratRegular;
                font-size: 1.1vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    border-radius: 2px;
                }

                @media only screen and (max-width: 499px) {
                    border-radius: 2px;
                    font-size: $mobile16px;
                }
            }
        }

        &_label {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            line-height: 0.7vw;
            color: $textDarkLight;
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
            }
        }
    }
}

.buttonsWrapper {
    display: flex;

    @media only screen and (max-width: 499px) {
        display: block;
        flex-wrap: wrap;
    }

}

.startAllBotsBtn {
    @include mainBtnStyle;
    @include greenBtnWithBorderAndBackground;
    font-family: $MontserratRegular;
    height: 3.91vw;
    display: flex;
    justify-content: center;
    width: 15vw;
    margin-left: 2.75vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: $tablet60px;
        margin: 2.61vw 0 2vw 7vw;
        flex: 1;
    }

    @media only screen and (max-width: 499px) {
        height: $mobile60px;
        width: calc(50% - 4vw);
        font-size: $mobile10px;
        margin: 0 4vw $mobile20px 0;
    }

    &:disabled {
       cursor: not-allowed;
       opacity: 0.5;
    }
    &:disabled:hover {
       background-color: #14d999;
       color: #fff;
       border: 1px solid transparent;
    }
}

.greenRedButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: $tablet20px;
    }
}
