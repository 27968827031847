@import '../../assets/styles/variables';
@import '../../assets/styles/mixin.scss';

.deleteModal {

    &__title {
        font-family: $MontserratRegular;
        font-size: 1.7vw;
        line-height: 2vw;
        margin-bottom: 2vw;
        color: #1F1F1F;
        text-align: center;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: $tablet24px;
            margin-bottom: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            margin-bottom: $mobile30px;
        }
    }

    &__close {
        position: absolute;
        top: 1.7vw;
        right: 1.7vw;
        cursor: pointer;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            top: 3.7vw;
            right: 3.7vw;
        }

        @media only screen and (max-width: 499px) {
            top: 3.7vw;
            right: 3.7vw;
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: center;
    }

    &__no,
    &__yes {
        @include mainBtnStyle;
        font-family: $MontserratRegular;
        height: 2.4vw;
        width: fit-content;
        min-width: 7vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            height: $tablet60px;
            width: 100%;
            font-size: $tablet16px;
            min-width: 21vw;
        }

        @media only screen and (max-width: 499px) {
            height: $tablet60px;
            width: 100%;
            font-size: 3.2vw;
            min-width: 21vw;
        }
    }

    &__no {
        @include greenBtnWithBorderAndBackground;
        margin-right: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-right: 5vw;
        }

        @media only screen and (max-width: 499px) {
            margin-right: 5vw;
        }
    }

    &__yes {
        @include redBtnWithBorderAndBackground;
        margin-left: 1vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-left: 5vw;
        }

        @media only screen and (max-width: 499px) {
            margin-left: 5vw;
        }
    }
}
