@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.botsWrapper {
    width: 74.6vw;
    margin: 1.8vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__bot {
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: $tablet40px;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
        }

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 0.8vw;
            line-height: 0.8vw;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            margin-bottom: 1.9vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 2.14vw;
                line-height: $tablet32px;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.3vw;
                line-height: $mobile24px;
                margin-bottom: 7vw;
            }
        }
    }
}

.buttonsWrapper {
    display: flex;

    @media only screen and (max-width: 499px) {
        display: block;
        flex-wrap: wrap;
    }

}

.cancelAllBotsBtn {
    @include mainBtnStyle;
    @include redBtnWithBorderAndBackground;
    font-family: $MontserratRegular;
    height: 3.91vw;
    display: flex;
    justify-content: center;
    width: 10vw;
    margin-left: 2.75vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: $tablet60px;
        margin: 2.61vw 0 2vw 7vw;
        flex: 1;
    }

    @media only screen and (max-width: 499px) {
        height: $mobile60px;
        width: calc(50% - 4vw);
        font-size: $mobile10px;
        margin: 0 4vw $mobile20px 0;
    }
}

.startAllBotsBtn {
    @include mainBtnStyle;
    @include greenBtnWithBorderAndBackground;
    font-family: $MontserratRegular;
    height: 3.91vw;
    display: flex;
    justify-content: center;
    width: 10vw;
    margin-left: 2.75vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        height: $tablet60px;
        margin: 2.61vw 0 2vw 7vw;
        flex: 1;
    }

    @media only screen and (max-width: 499px) {
        height: $mobile60px;
        width: calc(50% - 4vw);
        font-size: $mobile10px;
        margin: 0 4vw $mobile20px 0;
    }
}

.greenRedButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: $tablet20px;
    }
}
