@import '../assets/styles/variables';

    .flag-select__btn {
        outline: none;
        cursor: pointer;
    }

    .flag-select__options {
        display: flex;
        border: none;
        box-shadow: 0 0 1vw rgba(0, 0, 0, 0.1);
        right: 0;

        @media only screen and (max-width: 499px) {
            right: auto;
            left: 4vw;
        }
    }

    .flag-select__option {
        span {
            width: fit-content !important;
            height: 19px !important;
            display: flex;
            align-items: center;
        }
    }

    .flag-select__option:not(.flag-select__option--placeholder):hover,
    .flag-select__option:not(.flag-select__option--placeholder):focus {
        background: none;
    }
