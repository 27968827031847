@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.statisticsWrapper {
    width: 74.6vw;
    margin: 1.8vw 7.85vw 0 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__bot {

        &_title {
            font-family: $MontserratRegular;
            color: $textDarkLight;
            font-size: 0.8vw;
            line-height: 0.8vw;
            letter-spacing: 0.1vw;
            text-transform: uppercase;
            margin-bottom: 4vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: 2.14vw;
                line-height: $tablet32px;
                margin-bottom: 4vw;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.3vw;
                line-height: $mobile24px;
                margin-bottom: 7vw;
            }
        }
    }

    &__noData {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 1vw;
        line-height: 2vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;
        width: 50%;
        text-align: center;
        margin: 0 auto;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 2vw;
            line-height: 4vw;
            width: 100%;
        }

        @media only screen and (max-width: 499px) {
            font-size: 4vw;
            line-height: 6vw;
            width: 100%;
        }
    }
}
