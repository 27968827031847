$MontserratLight: 'MontserratLight', Arial, Helvetica, sans-serif;
$MontserratRegular: 'MontserratRegular', Arial, Helvetica, sans-serif;
$Montserrat700: 'Montserrat700', Arial, Helvetica, sans-serif;

$greenGradient: linear-gradient(0deg, #14d999, #14d999);
$footerGradient: linear-gradient(170.2deg, #007aff 14.23%, #7a65ff 87.98%);

$bodyColor: #fafbff;
$cabinerBackColor: #fcfcfc;
$black: #000000;
$white: #ffffff;
$textDark: #232323;
$textDarkLight: #151719;
$greyLight: #505761;
$greyDark: #3e3e3e;
$greyButton: #92969c;
$greenLight: #14d999;
$blueDark: #007aff;
$red: #ff455c;
$inputColor: #f2f3f9;
$dottedLine: #dcdcdc;
$borderColor: #efefef;

// tablet sizes

$tablet10px: 1.31vw;
$tablet12px: 1.6vw;
$tablet14px: 1.8vw;
$tablet16px: 2.1vw;
$tablet18px: 2.35vw;
$tablet20px: 2.61vw;
$tablet24px: 3.14vw;
$tablet26px: 3.39vw;
$tablet28px: 3.65vw;
$tablet30px: 3.91vw;
$tablet32px: 4.17vw;
$tablet36px: 4.7vw;
$tablet40px: 5.21vw;
$tablet48px: 6.25vw;
$tablet50px: 6.52vw;
$tablet55px: 7vw;
$tablet60px: 7.82vw;
$tablet64px: 8.34vw;
$tablet70px: 9.12vw;
$tablet80px: 10.42vw;
$tablet88px: 11.5vw;
$tablet176px: 22.93vw;

// mobile sizes

$mobile10px: 2.67vw;
$mobile12px: 3.2vw;
$mobile16px: 4.3vw;
$mobile20px: 5.34vw;
$mobile24px: 6.4vw;
$mobile25px: 6.7vw;
$mobile28px: 7.9vw;
$mobile30px: 8vw;
$mobile32px: 8.5vw;
$mobile34px: 8.7vw;
$mobile42px: 11vw;
$mobile55px: 14.7vw;
$mobile60px: 16vw;
$mobile65px: 17.4vw;
$mobile68px: 18.15vw;
$mobile70px: 19vw;
$mobile75px: 20vw;
$mobile150px: 40vw;
